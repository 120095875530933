.Initial-div{
  display: flex;
  flex-direction: row;
  align-self: center;
  color: white;
  justify-content: flex-end;
  width: 100vw;
}

.Text-div{
  color: #F5F5F5;
  font-size: 170%;
  align-self: center;
  font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
  margin-right: 25%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  width: 100%;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

.Text-link{
  padding-left: 5%;
  margin-bottom: 0%;
  padding-top: 3vh;

}

.Left-icon{
  padding-right: 50vw;
  padding-top: 2.5vh;
}

@media screen and (max-width: 1900px){
  .Left-icon{
    padding-right: 48vw;
    padding-top: 2.5vh;
  }
}

@media screen and (max-width: 1700px){
  .Left-icon{
    padding-right: 44vw;
  }
}

@media screen and (max-width: 1400px){
  .Left-icon{
    padding-right: 40vw;
  }
}

@media screen and (max-width: 1200px){
  .Left-icon{
    padding-right: 34vw;
  }
}

@media screen and (max-width: 980px){
  .Left-icon{
    padding-right: 27vw;
  }
}

@media screen and (max-width: 760px){
  .Left-icon{
    padding-right: 18vw;
    padding-top: 2.5vh;

  }

}

@media screen and (max-width: 610px){
  .Left-icon{
    padding-right: 10vw;
  }
}

@media screen and (max-width: 530px){
  .Left-icon{
    padding-right: 10vw;
        padding-top: 2.5vh;

  }

  .Text-div{
    color: #F5F5F5;
    font-size: 150%;
    align-self: center;
    font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
    margin-right: 15%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    
    width: 100%;
    -moz-user-select:none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
  }
}

@media screen and (max-width: 480px){
  .Left-icon{
    padding-right: 0vw;
        padding-top: 2.5vh;
  }

  .Text-link{
    padding-left: 5%;
    margin-top: 3%;
  }

  .Text-div{
    color: #F5F5F5;
    font-size: 150%;
    align-self: center;
    font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
    margin-right: 15%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;

    width: 100%;
    -moz-user-select:none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
  }
}

@media screen and (max-width: 410px){
  .Left-icon{
    padding-right: 0vw;

  }

  .Text-link{
    padding-left: 5%;
    margin-top: 0%;
  }

  .Text-div{
    color: #F5F5F5;
    font-size: 140%;
    align-self: center;
    font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
    margin-right: 17%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;

    width: 100%;
    -moz-user-select:none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
  }
}

