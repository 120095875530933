.Footer-initial-div{
    background-color: #656565;
    display: flex;
    flex-direction: column;
    color: #F5F5F5;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    font-size: 130%;
}

.Footer-text{
    margin-right: 20%;
}