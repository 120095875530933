.App {
  text-align: center;
}


.App-header {
  background-color: rgb(67,67,68);
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  color: white;
  height: 8vh;
}
