.Initial-div{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

@media screen and (min-width: 1201px) {
    .Title-Kontakt{
        color: #848484;
        font-size: 320%;
        padding-top: 4%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 20px;
        padding-bottom: 2.55%;
    }
    
    .Text-Kontakt{
        color: #848484;
        font-size: 190%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 0.4%;
    }

    .Text-Kontakt-advance{
        color: #848484;
        font-size: 190%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 0%;
    }

    .Text-filler{
        color: #F5F5F5;
        -moz-user-select:none; /* firefox */
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE*/
        user-select: none; /* Standard syntax */
    }

    .Text-Kontakt-border{
        color: #848484;
        font-size: 190%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 1.4%;
        text-decoration: underline;
        text-decoration-color: #a8a8a8;
        text-decoration-thickness: 2px;
        text-underline-offset: 22px;
    }

    .MapFrame{
        margin-right: 20vh;
        width: 70vw;
        height: 45vh;
    }
}

@media screen and (max-width: 1200px) {
    .Title-Kontakt{
        color: #848484;
        font-size: 320%;
        padding-top: 4%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 20px;
        padding-bottom: 2.55%;
    }
    
    .Text-Kontakt{
        color: #848484;
        font-size: 190%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 0.4%;
    }

    .Text-Kontakt-advance{
        color: #848484;
        font-size: 190%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 0%;
    }

    .Text-filler{
        color: #F5F5F5;
        -moz-user-select:none; /* firefox */
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE*/
        user-select: none; /* Standard syntax */
    }

    .Text-Kontakt-border{
        color: #848484;
        font-size: 190%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 1.4%;
        text-decoration: underline;
        text-decoration-color: #a8a8a8;
        text-decoration-thickness: 2px;
        text-underline-offset: 18px;
    }

    .MapFrame{
        margin-right: 20vh;
        width: 70vw;
        height: 45vh;
    }
}

@media screen and (max-width: 900px) {
    .Title-Kontakt{
        color: #848484;
        font-size: 320%;
        padding-top: 4%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 20px;
        padding-bottom: 2.55%;
    }
    
    .Text-Kontakt{
        color: #848484;
        font-size: 190%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 0.4%;
    }

    .Text-Kontakt-advance{
        color: #848484;
        font-size: 190%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 0%;
    }

    .Text-filler{
        color: #F5F5F5;
        -moz-user-select:none; /* firefox */
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE*/
        user-select: none; /* Standard syntax */
    }

    .Text-Kontakt-border{
        color: #848484;
        font-size: 190%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 1.4%;
        text-decoration: underline;
        text-decoration-color: #a8a8a8;
        text-decoration-thickness: 2px;
        text-underline-offset: 12px;
    }

    .MapFrame{
        margin-right: 20vh;
        width: 70vw;
        height: 45vh;
    }
}

@media screen and (max-width: 669px) {
    .Title-Kontakt{
        color: #848484;
        font-size: 280%;
        padding-top: 4%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 20px;
        padding-bottom: 2.55%;
    }
    
    .Text-Kontakt{
        color: #848484;
        font-size: 170%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 1%;
    }

    .Text-Kontakt-advance{
        color: #848484;
        font-size: 170%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 0%;
    }

    .Text-filler{
        color: #F5F5F5;
        -moz-user-select:none; /* firefox */
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE*/
        user-select: none; /* Standard syntax */
    }

    .Text-Kontakt-border{
        color: #848484;
        font-size: 170%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 1.4%;
        text-decoration: underline;
        text-decoration-color: #a8a8a8;
        text-decoration-thickness: 2px;
        text-underline-offset: 10px;
    }

    .MapFrame{
        margin-right: 8vh;
        width: 75vw;
        height: 45vh;
    }
}

@media screen and (max-width: 539px) {
    .Title-Kontakt{
        color: #848484;
        font-size: 240%;
        padding-top: 4%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 12px;
        padding-bottom: 3.55%;
    }
    
    .Text-Kontakt{
        color: #848484;
        font-size: 140%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 1%;
    }

    .Text-Kontakt-advance{
        color: #848484;
        font-size: 140%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 0%;
    }

    .Text-filler{
        color: #F5F5F5;
        -moz-user-select:none; /* firefox */
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE*/
        user-select: none; /* Standard syntax */
    }

    .Text-Kontakt-border{
        color: #848484;
        font-size: 140%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        padding-bottom: 1.4%;
        text-decoration: underline;
        text-decoration-color: #a8a8a8;
        text-decoration-thickness: 2px;
        text-underline-offset: 7px;
    }

    .MapFrame{
        margin-right: 2vh;
        width: 80vw;
        height: 45vh;
    }
}

.Nadpis-div{
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.Map-div{
    height: 50vh;
    width: 100vw;
}

