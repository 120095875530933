.Initial-div{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.Usable-div{
    min-height: 84.4vh;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #848484;
}



@media screen and (min-width: 831px) {
    .Title-text{
        color: #848484;
        font-size: 320%;
        padding-top: 4%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 20px;
        padding-bottom: 1.9%;
        margin-left: 10%;
    }

    .Title-smaller-text{
        color: #848484;
        font-size: 220%;
        margin-top: 0.4%;
        padding-top: 2%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 6px;
        text-underline-offset: 20px;
        padding-bottom: 1.9%;
        margin-left: 10%;
    }
    
    .Text-column{
        color: #848484;
        margin-left: 10%;
        margin-right: 25%;
        font-size: 170%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        text-align: left;
    }
}

@media screen and (max-width: 830px) {
    .Title-text{
        color: #848484;
        font-size: 250%;
        padding-top: 4%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 18px;
        padding-bottom: 2.55%;
        margin-left: 10%;
    }

    .Title-smaller-text{
        color: #848484;
        font-size: 175%;
        margin-top: 0.4%;
        padding-top: 2%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 6px;
        text-underline-offset: 12px;
        padding-bottom: 1.9%;
        margin-left: 10%;
    }
    
    .Text-column{
        color: #848484;
        margin-left: 10%;
        margin-right: 15%;
        font-size: 150%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        text-align: left;
    }
}

@media screen and (max-width: 401px) {
    .Title-text{
        color: #848484;
        font-size: 230%;
        padding-top: 4%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 11px;
        padding-bottom: 2.55%;
        margin-left: 10%;
    }

    .Title-smaller-text{
        color: #848484;
        font-size: 160%;
        margin-top: 0.4%;
        padding-top: 2%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 6px;
        text-underline-offset: 8px;
        padding-bottom: 1.9%;
        margin-left: 10%;
    }
    
    .Text-column{
        color: #848484;
        margin-left: 10%;
        margin-right: 15%;
        font-size: 120%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        text-align: left;
    }
}



@media screen and (min-width: 1400px) {
    .Images-helper{
        display: flex;
        flex-direction: row;
        width: 100vw;
        justify-content: center;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }

    .FirstImage-div{
        display: flex;
        width: 606px;
        height: 436px;
        background-color: #848484;
        align-items: center;
        margin-right: 5%;
    
    }

    .SecondImage-div{
        display: flex;
        width: 606px;
        height: 436px;
        background-color: #848484;
        align-items: center;
        margin-left: 5%;
    }
}

@media screen and (max-width: 1399px) {
    .Images-helper{
        display: flex;
        flex-direction: column;
        width: 100vw;
        justify-content: center;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }

    .FirstImage-div{
        display: flex;
        width: 606px;
        height: 436px;
        background-color: #848484;
        align-items: center;
        margin-left: 25%;
        margin-bottom: 5%;
    }

    .SecondImage-div{
        display: flex;
        width: 606px;
        height: 436px;
        background-color: #848484;
        align-items: center;
        margin-left: 25%;
    }
}

@media screen and (max-width: 1000px) {
    .Images-helper{
        display: flex;
        flex-direction: column;
        width: 100vw;
        justify-content: center;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }

    .FirstImage-div{
        display: flex;
        width: 606px;
        height: 436px;
        background-color: #848484;
        align-items: center;
        margin-left: 10%;
        margin-bottom: 5%;
    }

    .SecondImage-div{
        display: flex;
        width: 606px;
        height: 436px;
        background-color: #848484;
        align-items: center;
        margin-left: 10%;
    }
}

@media screen and (max-width: 700px) {
    .Images-helper{
        display: flex;
        flex-direction: column;
        width: 100vw;
        justify-content: center;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }

    .FirstImage-div{
        display: flex;
        width: 456px;
        height: 286px;
        background-color: #848484;
        align-items: center;
        margin-left: 10%;
        margin-bottom: 5%;
    }

    .SecondImage-div{
        display: flex;
        width: 456px;
        height: 286px;
        background-color: #848484;
        align-items: center;
        margin-left: 10%;
    }
}

@media screen and (max-width: 530px) {
    .Images-helper{
        display: flex;
        flex-direction: column;
        width: 100vw;
        justify-content: center;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }

    .FirstImage-div{
        display: flex;
        width: 356px;
        height: 186px;
        background-color: #848484;
        align-items: center;
        margin-left: 10%;
        margin-bottom: 5%;
    }

    .SecondImage-div{
        display: flex;
        width: 356px;
        height: 186px;
        background-color: #848484;
        align-items: center;
        margin-left: 10%;
    }
}

@media screen and (max-width: 415px) {
    .Images-helper{
        display: flex;
        flex-direction: column;
        width: 100vw;
        justify-content: center;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }

    .FirstImage-div{
        display: flex;
        width: 356px;
        height: 186px;
        background-color: #848484;
        align-items: center;
        margin-left: 2%;
        margin-bottom: 5%;
    }

    .SecondImage-div{
        display: flex;
        width: 356px;
        height: 186px;
        background-color: #848484;
        align-items: center;
        margin-left: 2%;
    }
}

@media screen and (max-width: 380px) {
    .Images-helper{
        display: flex;
        flex-direction: column;
        width: 100vw;
        justify-content: center;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }

    .FirstImage-div{
        display: flex;
        width: 306px;
        height: 136px;
        background-color: #848484;
        align-items: center;
        margin-left: 1%;
        margin-bottom: 5%;
    }

    .SecondImage-div{
        display: flex;
        width: 306px;
        height: 136px;
        background-color: #848484;
        align-items: center;
        margin-left: 1%;
    }
}

.First-image{
    width: 98%;
    height: 94%;
    padding-bottom: 1%;
    padding-left: 1%;
}



.Second-image{
    width: 97%;
    height: 93%;
    padding-left: 1.5%;
    padding-bottom: 1%;
}