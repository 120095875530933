.Initial-div{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.Opacity-div{
    background-image: url(../Images/backgroundOsang.jpg);
    background-repeat: no-repeat;
    background-size:cover;
    width: 100%;
    height: 80vh;
}

.Svg-div{
    background-image: url(../SVG/group.svg);
    background-repeat: no-repeat;
    height: 95%;
    width: 95%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: start;
}

@media screen and (min-width: 2351px) {
    .Title-position{
        font-size: 360%;
        margin-top: 10%;
        margin-left: 5%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;

    }
    
    .SubTitle-position{
        margin-top: 0.5%;
        margin-left: 5%;
        font-size: 180%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
        
    }

    .Button-div{
        flex-direction: row;
        margin-top: 4%;
        margin-left: 14%;
    }
    
    .ButtonSluzby{
        font-size: 180%;
        align-self: flex-start;
        height: 140%;
        color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        border-style: solid;
        
    }

    .ButtonKontakt{
        font-size: 180%;
        align-self: flex-start;
        margin-left: 0.4%;
        height: 140%;
        background-color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        color: white;
        border-style: solid;
    
    }
}


@media screen and (max-width: 2350px) {
    .Title-position{
        font-size: 360%;
        margin-top: 12%;
        margin-left: 5%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;

    }
    
    .SubTitle-position{
        margin-top: 0.5%;
        margin-left: 5%;
        font-size: 180%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
        
    }

    .Button-div{
        flex-direction: row;
        margin-top: 4%;
        margin-left: 14%;
    }
    
    .ButtonSluzby{
        font-size: 180%;
        align-self: flex-start;
        height: 140%;
        color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        border-style: solid;
        
    }

    .ButtonKontakt{
        font-size: 180%;
        align-self: flex-start;
        margin-left: 0.4%;
        height: 140%;
        background-color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        color: white;
        border-style: solid;
    
    }
}


@media screen and (max-width: 2200px) {
    .Title-position{
        font-size: 360%;
        margin-top: 12%;
        margin-left: 5%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;

    }
    
    .SubTitle-position{
        margin-top: 0.5%;
        margin-left: 5%;
        font-size: 180%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    
    }

    .Button-div{
        flex-direction: row;
        margin-top: 5.5%;
        margin-left: 16%;
    }
    
    .ButtonSluzby{
        font-size: 180%;
        align-self: flex-start;
        height: 140%;
        color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        border-style: solid;
        
    }

    .ButtonKontakt{
        font-size: 180%;
        align-self: flex-start;
        margin-left: 0.4%;
        height: 140%;
        background-color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        color: white;
        border-style: solid;
    
    }
}


@media screen and (max-width: 1921px) {
    .Title-position{
        font-size: 360%;
        margin-top: 14%;
        margin-left: 5%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;

    }
    
    .SubTitle-position{
        margin-top: 0.5%;
        margin-left: 5%;
        font-size: 180%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
        
    }

    .Button-div{
        flex-direction: row;
        margin-top: 5%;
        margin-left: 18%;
    }
    
    .ButtonSluzby{
        font-size: 180%;
        align-self: flex-start;
        height: 140%;
        color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        border-style: solid;
        
    }

    @media screen and (max-width: 1800px) {
        .Title-position{
            font-size: 360%;
            margin-top: 15%;
            margin-left: 6%;
            font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
            color: #F5F5F5;

        }
        
        .SubTitle-position{
            margin-top: 0.5%;
            margin-left: 6%;
            font-size: 180%;
            font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
            color: #F5F5F5;
        }
    
        .Button-div{
            flex-direction: row;
            margin-top: 6%;
            margin-left: 20%;
        }
        
        .ButtonSluzby{
            font-size: 180%;
            align-self: flex-start;
            height: 140%;
            color: #0078D4;
            border-color: #0078D4;
            border-width: 4px;
            border-style: solid;
            
        }
        
        .ButtonKontakt{
            font-size: 180%;
            align-self: flex-start;
            margin-left: 0.4%;
            height: 140%;
            background-color: #0078D4;
            border-color: #0078D4;
            border-width: 4px;
            color: white;
            border-style: solid;
        
        }
    }

    @media screen and (max-width: 1700px) {
        .Title-position{
            font-size: 360%;
            margin-top: 15%;
            margin-left: 6%;
            font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
            color: #F5F5F5;
        }
        
        .SubTitle-position{
            margin-top: 0.5%;
            margin-left: 6%;
            font-size: 180%;
            font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
            color: #F5F5F5;
        }
    
        .Button-div{
            flex-direction: row;
            margin-top: 7%;
            margin-left: 20%;
        }
        
        .ButtonSluzby{
            font-size: 180%;
            align-self: flex-start;
            height: 140%;
            color: #0078D4;
            border-color: #0078D4;
            border-width: 4px;
            border-style: solid;
            
        }
        
        .ButtonKontakt{
            font-size: 180%;
            align-self: flex-start;
            margin-left: 0.4%;
            height: 140%;
            background-color: #0078D4;
            border-color: #0078D4;
            border-width: 4px;
            color: white;
            border-style: solid;
        
        }
    }
    
    .ButtonKontakt{
        font-size: 180%;
        align-self: flex-start;
        margin-left: 0.4%;
        height: 140%;
        background-color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        color: white;
        border-style: solid;
    
    }
}

@media screen and (max-width: 1500px) {
    .Title-position{
        font-size: 360%;
        margin-top: 19%;
        margin-left: 8%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    }
    
    .SubTitle-position{
        margin-top: 0.5%;
        margin-left: 8%;
        font-size: 180%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    }

    .Button-div{
        flex-direction: row;
        margin-top: 7%;
        margin-left: 23%;
    }
    
    .ButtonSluzby{
        font-size: 180%;
        align-self: flex-start;
        height: 140%;
        color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        border-style: solid;
        
    }

    @media screen and (max-width: 1300px) {
        .Title-position{
            font-size: 360%;
            margin-top: 20%;
            margin-left: 10%;
            font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
            color: #F5F5F5;
        }
        
        .SubTitle-position{
            margin-top: 0.5%;
            margin-left: 10%;
            font-size: 180%;
            font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
            color: #F5F5F5;
        }
    
        .Button-div{
            flex-direction: row;
            margin-top: 9%;
            margin-left: 25%;
        }
        
        .ButtonSluzby{
            font-size: 180%;
            align-self: flex-start;
            height: 140%;
            color: #0078D4;
            border-color: #0078D4;
            border-width: 4px;
            border-style: solid;
            
        }
        
        .ButtonKontakt{
            font-size: 180%;
            align-self: flex-start;
            margin-left: 0.4%;
            height: 140%;
            background-color: #0078D4;
            border-color: #0078D4;
            border-width: 4px;
            color: white;
            border-style: solid;
        
        }
    }

    @media screen and (max-width: 1200px) {
        .Title-position{
            font-size: 360%;
            margin-top: 22%;
            margin-left: 10%;
            font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
            color: #F5F5F5;
        }
        
        .SubTitle-position{
            margin-top: 0.5%;
            margin-left: 10%;
            font-size: 180%;
            font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
            color: #F5F5F5;
        }
    
        .Button-div{
            flex-direction: row;
            margin-top: 9%;
            margin-left: 29%;
        }
        
        .ButtonSluzby{
            font-size: 180%;
            align-self: flex-start;
            height: 140%;
            color: #0078D4;
            border-color: #0078D4;
            border-width: 4px;
            border-style: solid;
            
        }
        
        .ButtonKontakt{
            font-size: 180%;
            align-self: flex-start;
            margin-left: 0.4%;
            height: 140%;
            background-color: #0078D4;
            border-color: #0078D4;
            border-width: 4px;
            color: white;
            border-style: solid;
        
        }
    }

    @media screen and (max-width: 1100px) {
        .Title-position{
            font-size: 360%;
            margin-top: 25%;
            margin-left: 10%;
            font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
            color: #F5F5F5;
        }
        
        .SubTitle-position{
            margin-top: 0.5%;
            margin-left: 10%;
            font-size: 180%;
            font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
            color: #F5F5F5;
        
        }
    
        .Button-div{
            flex-direction: row;
            margin-top: 10%;
            margin-left: 32%;
        }
        
        .ButtonSluzby{
            font-size: 180%;
            align-self: flex-start;
            height: 140%;
            color: #0078D4;
            border-color: #0078D4;
            border-width: 4px;
            border-style: solid;
            
        }
        
        .ButtonKontakt{
            font-size: 180%;
            align-self: flex-start;
            margin-left: 0.4%;
            height: 140%;
            background-color: #0078D4;
            border-color: #0078D4;
            border-width: 4px;
            color: white;
            border-style: solid;
        
        }
    }
    
    .ButtonKontakt{
        font-size: 180%;
        align-self: flex-start;
        margin-left: 0.4%;
        height: 140%;
        background-color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        color: white;
        border-style: solid;
    
    }
}

@media screen and (max-width: 1000px) {
    .Title-position{
        font-size: 360%;
        margin-top: 25%;
        margin-left: 12%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    }
    
    .SubTitle-position{
        margin-top: 0.5%;
        margin-left: 12%;
        font-size: 180%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    
    }

    .Button-div{
        flex-direction: row;
        margin-top: 12%;
        margin-left: 33%;
    }
    
    .ButtonSluzby{
        font-size: 180%;
        align-self: flex-start;
        height: 140%;
        color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        border-style: solid;
        
    }
    
    .ButtonKontakt{
        font-size: 180%;
        align-self: flex-start;
        margin-left: 0.4%;
        height: 140%;
        background-color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        color: white;
        border-style: solid;
    
    }
}

@media screen and (max-width: 950px) {
    .Title-position{
        font-size: 360%;
        margin-top: 30%;
        margin-left: 12%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    }
    
    .SubTitle-position{
        margin-top: 0.5%;
        margin-left: 12%;
        font-size: 180%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    }

    .Button-div{
        flex-direction: row;
        margin-top: 12%;
        margin-left: 38%;
    }
    
    .ButtonSluzby{
        font-size: 180%;
        align-self: flex-start;
        height: 140%;
        color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        border-style: solid;
        
    }
    
    .ButtonKontakt{
        font-size: 180%;
        align-self: flex-start;
        margin-left: 0.4%;
        height: 140%;
        background-color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        color: white;
        border-style: solid;
    
    }
}

@media screen and (max-width: 800px) {
    .Title-position{
        font-size: 360%;
        margin-top: 38%;
        margin-left: 14%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    }
    
    .SubTitle-position{
        margin-top: 0.5%;
        margin-left: 14%;
        font-size: 180%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    
    }

    .Button-div{
        flex-direction: row;
        margin-top: 10%;
        margin-left: 45%;
    }
    
    .ButtonSluzby{
        font-size: 180%;
        align-self: flex-start;
        height: 140%;
        color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        border-style: solid;
        
    }
    
    .ButtonKontakt{
        font-size: 180%;
        align-self: flex-start;
        margin-left: 0.4%;
        height: 140%;
        background-color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        color: white;
        border-style: solid;
    
    }
}

@media screen and (max-width: 680px) {
    .Title-position{
        font-size: 360%;
        margin-top: 38%;
        margin-left: 14%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    }
    
    .SubTitle-position{
        margin-top: 0.5%;
        margin-left: 14%;
        font-size: 180%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    
    }

    .Button-div{
        flex-direction: row;
        margin-top: 15%;
        margin-left: 40%;
    }
    
    .ButtonSluzby{
        font-size: 180%;
        align-self: flex-start;
        height: 140%;
        color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        border-style: solid;
        
    }
    
    .ButtonKontakt{
        font-size: 180%;
        align-self: flex-start;
        margin-left: 0.4%;
        height: 140%;
        background-color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        color: white;
        border-style: solid;
    
    }
}

@media screen and (max-width: 640px) {
    .Title-position{
        font-size: 360%;
        margin-top: 45%;
        margin-left: 14%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    }
    
    .SubTitle-position{
        margin-top: 0.5%;
        margin-left: 14%;
        font-size: 180%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    }

    .Button-div{
        flex-direction: row;
        margin-top: 13%;
        margin-left: 20%;
    }
    
    .ButtonSluzby{
        font-size: 180%;
        align-self: flex-start;
        height: 140%;
        color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        border-style: solid;
        
    }
    
    .ButtonKontakt{
        font-size: 180%;
        align-self: flex-start;
        margin-left: 0.4%;
        height: 140%;
        background-color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        color: white;
        border-style: solid;
    
    }
}

@media screen and (max-width: 500px) {
    .Title-position{
        font-size: 360%;
        margin-top: 55%;
        margin-left: 14%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    }
    
    .SubTitle-position{
        margin-top: 0.5%;
        margin-left: 14%;
        font-size: 180%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    
    }

    .Button-div{
        flex-direction: row;
        margin-top: 18%;
        margin-left: 20%;
    }
    
    .ButtonSluzby{
        font-size: 180%;
        align-self: flex-start;
        height: 140%;
        color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        border-style: solid;
        
    }

    @media screen and (max-width: 470px) {
        .Title-position{
            font-size: 360%;
            margin-top: 55%;
            margin-left: 14%;
            font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
            color: #F5F5F5;
        }
        
        .SubTitle-position{
            margin-top: 0.5%;
            margin-left: 14%;
            font-size: 180%;
            font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
            color: #F5F5F5;
        
        }
    
        .Button-div{
            flex-direction: row;
            margin-top: 15%;
            margin-left: 20%;
        }
        
        .ButtonSluzby{
            font-size: 150%;
            align-self: flex-start;
            height: 140%;
            color: #0078D4;
            border-color: #0078D4;
            border-width: 4px;
            border-style: solid;
            
        }
        
        .ButtonKontakt{
            font-size: 150%;
            align-self: flex-start;
            margin-left: 0.4%;
            height: 140%;
            background-color: #0078D4;
            border-color: #0078D4;
            border-width: 4px;
            color: white;
            border-style: solid;
        
        }
    }
    
    .ButtonKontakt{
        font-size: 180%;
        align-self: flex-start;
        margin-left: 0.4%;
        height: 140%;
        background-color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        color: white;
        border-style: solid;
    
    }
}

@media screen and (max-width: 450px) {
    .Title-position{
        font-size: 300%;
        margin-top: 55%;
        margin-left: 14%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    }
    
    .SubTitle-position{
        margin-top: 0.5%;
        margin-left: 14%;
        font-size: 150%;
        font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
        color: #F5F5F5;
    
    }

    .Button-div{
        flex-direction: row;
        margin-top: 10%;
        margin-left: 22%;
    }
    
    .ButtonSluzby{
        font-size: 140%;
        align-self: flex-start;
        height: 100%;
        color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        border-style: solid;
        
    }
    
    .ButtonKontakt{
        font-size: 140%;
        align-self: flex-start;
        margin-left: 5.4%;
        margin-top: 4%;
        height: 100%;
        background-color: #0078D4;
        border-color: #0078D4;
        border-width: 4px;
        color: white;
        border-style: solid;
    
    }
}

.Who-we-are-div{
    color: black;
    display: flex;
}

.Left-side-div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    background-color: #848484;
    color: #FFFF;
    font-family: 'Nimbus Sans L', 'Sergoe UI', 'Helvetica';
    border-color: #707070;
    border-width: 2px;
    border-style: none none solid none;
}

@media screen and (max-width: 550px) {
    .Title-Left-side {
        font-size: 220%;
        margin-top: 4%;
        margin-left: 10%;
        margin-bottom: 7%;
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 15px;
    }
    
    .Left-side-text{
        margin-left: 10%;
        margin-right: 7%;
        text-align: left;
        word-spacing: 0px;
        font-size: 110%;
        margin-bottom: 5%;
    
    }
}

@media screen and (min-width: 551px) {
    .Title-Left-side {
        font-size: 320%;
        margin-top: 4%;
        margin-left: 15%;
        margin-bottom: 3%;
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 22px;
    }
    
    .Left-side-text{
        margin-left: 15%;
        margin-right: 10%;
        text-align: left;
        word-spacing: -3px;
        font-size: 170%;
        margin-bottom: 5%;
    
    }
}


.Right-side-div{
    color: #848484;
    font-size: 175%;
    display: flex;
    flex-direction: column;
    background-color: #F5F5F5;
    width: 50%;
    border-color: #707070;
    border-width: 2px;
    border-style: none none solid none;
}

.Right-helper-div{
    display: flex;
    flex-direction: row;
    
}

.Truck-div{
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    margin-top: 10%;
}

.Star-div{
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    margin-top: 10%;
}

.Shield-div{
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    margin-bottom: 5%;
}

@media screen and (max-width: 1599px){
    .Truck-div{
        display: flex;
        flex-direction: column;
        margin-left: 15%;
        margin-top: 20%;
    }
    
    .Star-div{
        display: flex;
        flex-direction: column;
        margin-left: 15%;
        margin-top: 20%;
    }
    
    .Shield-div{
        display: flex;
        flex-direction: column;
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

@media screen and (max-width: 1260px){
    .Truck-div{
        display: flex;
        flex-direction: column;
        margin-left: 15%;
        margin-top: 30%;
    }
    
    .Star-div{
        display: flex;
        flex-direction: column;
        margin-left: 15%;
        margin-top: 30%;
    }
    
    .Shield-div{
        display: flex;
        flex-direction: column;
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

@media screen and (max-width: 990px){
    .Truck-div{
        display: flex;
        flex-direction: column;
        margin-left: 15%;
        margin-top: 50%;
    }
    
    .Star-div{
        display: flex;
        flex-direction: column;
        margin-left: 15%;
        margin-top: 50%;
    }
    
    .Shield-div{
        display: flex;
        flex-direction: column;
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

@media screen and (max-width: 880px){
    .Truck-div{
        display: flex;
        flex-direction: column;
        margin-left: 10%;
        margin-top: 85%;
    }
    
    .Star-div{
        display: flex;
        flex-direction: column;
        margin-left: 10%;
        margin-top: 85%;
    }
    
    .Shield-div{
        display: flex;
        flex-direction: column;
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

@media screen and (max-width: 670px){
    .Truck-div{
        display: flex;
        flex-direction: column;
        margin-left: 10%;
        margin-top: 35%;
        font-size: 75%;
    }
    
    .Star-div{
        display: flex;
        flex-direction: column;
        margin-left: 10%;
        margin-top: 35%;
        font-size: 75%;

    }
    
    .Shield-div{
        display: flex;
        flex-direction: column;
        margin-top: 5%;
        margin-bottom: 5%;
        font-size: 75%;

    }
}

@media screen and (max-width: 500px){
    .Truck-div{
        display: flex;
        flex-direction: column;
        margin-left: 5%;
        margin-top: 75%;
        font-size: 60%;
    }
    
    .Star-div{
        display: flex;
        flex-direction: column;
        margin-left: 0%;
        margin-top: 75%;
        font-size: 60%;

    }
    
    .Shield-div{
        display: flex;
        flex-direction: column;
        margin-top: 5%;
        margin-bottom: 5%;
        font-size: 60%;

    }
}

.Phone-contact-div{
    display: flex;
    background-color: #F5F5F5;
    flex-direction: row;
    width: 100vw;
    color: #707070;
    border-color: #707070;
    border-width: 2px;
    border-style: none none solid none;
}

@media screen and (min-width: 1557px) {
    
    .Phone-contact-title{
        margin-top: 2%;
        margin-bottom: 2%;
        font-size: 350%;
        margin-left: 7.5%;
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 20px;
    }
    
    .Phone-contact-text{
        margin-top: 2%;
        margin-bottom: 2%;
        font-size: 350%;
        margin-left: 35%;
    
    }

}

@media screen and (max-width: 1556px) {
    
    .Phone-contact-title{
        margin-top: 2%;
        margin-bottom: 2%;
        font-size: 300%;
        margin-left: 7.5%;
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 20px;
    }
    
    .Phone-contact-text{
        margin-top: 2%;
        margin-bottom: 2%;
        font-size: 300%;
        margin-left: 25%;
    
    }

}

@media screen and (max-width: 1150px) {
    
    .Phone-contact-title{
        margin-top: 2%;
        margin-bottom: 2%;
        font-size: 250%;
        margin-left: 7.5%;
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 20px;
    }
    
    .Phone-contact-text{
        margin-top: 2%;
        margin-bottom: 2%;
        font-size: 250%;
        margin-left: 25%;
    }

}
@media screen and (max-width: 600px) {
    
    .Phone-contact-title{
        margin-top: 2%;
        margin-bottom: 2%;
        font-size: 180%;
        margin-left: 3.5%;
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 40px;
    }
    
    .Phone-contact-text{
        margin-top: 2%;
        margin-bottom: 2%;
        font-size: 180%;
        margin-left: 2%;
        margin-right: 5%;
    }

}

@media screen and (max-width: 460px) {
    
    .Phone-contact-title{
        margin-top: 2%;
        margin-bottom: 2%;
        font-size: 150%;
        margin-left: 3.5%;
        text-decoration: underline;
        text-decoration-color: #0078D4;
        text-decoration-thickness: 8px;
        text-underline-offset: 40px;
    }
    
    .Phone-contact-text{
        margin-top: 2%;
        margin-bottom: 2%;
        font-size: 150%;
        margin-left: 2%;
        margin-right: 5%;
    }

}


.Thanks-div{
    padding-top: 1%;
    padding-bottom: 1%;
    color: #848484;
    font-size: 150%;
    width: 100vw;
    border-color: #707070;
    background-color: #F5F5F5;
    border-width: 2px;
    border-style: none none solid none;
}

@media screen and (max-width: 500px) {
    .Thanks-div{
        padding-top: 1%;
        padding-bottom: 1%;
        color: #848484;
        font-size: 100%;
        width: 100vw;
        border-color: #707070;
        background-color: #F5F5F5;
        border-width: 2px;
        border-style: none none solid none;
    }
}

